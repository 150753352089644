import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../theme/layout"
import SEO from "../../components/header/seo"
import BlogWrapper from "../../components/blog/blog-wrapper"

const BlogPage = ({ pageContext, data, location }) => {
  const blogQueryData = useStaticQuery(BLOG_QUERY)
  return (
    <Layout
      pathContext={pageContext}
      location={location}
      bannerDetails={{
        title: "Blog",
        description:
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et",
        image: blogQueryData.imageSharp.gatsbyImageData,
      }}
    >
      <SEO />
      <BlogWrapper></BlogWrapper>
    </Layout>
  )
}

export default BlogPage

// ===============
//      QUERY
// ===============

const BLOG_QUERY = graphql`
  {
    imageSharp(fixed: { originalName: { eq: "blog-banner-image.png" } }) {
      gatsbyImageData
    }
  }
`
